import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MuiAvatar from '@mui/material/Avatar'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { nameInitials } from 'utils/stringUtils'
import { SubSubHeader } from 'components/StyledComponents'
import { getThumbnailUrl } from 'utils/fileUtils'

type UserAvatarProps = {
  size: number
  avatarUrl?: string | null
  name?: string | null
  email?: string | null
  phone?: string | null
  company?: string | null
  identifier?: string | null
  address?: string | null
  apartment?: string | null
}

const Avatar = styled(MuiAvatar)`
  cursor: pointer;
`
const UserAvatar: React.FC<UserAvatarProps> = ({
  size,
  avatarUrl,
  name,
  email,
  phone,
  company,
  identifier,
  address,
  apartment,
}) => {
  const { formatMessage } = useIntl()
  const [userPopoverAnchorEl, setUserPopoverAnchorEl] =
    useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
    setUserPopoverAnchorEl(event.currentTarget)
  }

  const handleCloseUserPopover = (): void => {
    setUserPopoverAnchorEl(null)
  }

  const openUserPopover = Boolean(userPopoverAnchorEl)

  return (
    <>
      <Avatar
        src={getThumbnailUrl(avatarUrl)}
        sx={{ width: size, height: size, fontSize: size / 2 }}
        onClick={handleClick}
      >
        {nameInitials(name ?? '')}
      </Avatar>

      <Popover
        open={openUserPopover}
        anchorEl={userPopoverAnchorEl}
        onClose={handleCloseUserPopover}
        sx={{
          marginTop: -4,
          marginLeft: -2,
        }}
      >
        <Stack direction="row" spacing={1} p={2}>
          <Avatar
            src={avatarUrl ?? undefined}
            sx={{ width: 50, height: 50, fontSize: 25 }}
          >
            {nameInitials(name ?? '')}
          </Avatar>
          <Stack flexGrow={1}>
            <SubSubHeader>{name}</SubSubHeader>
            <Typography variant="body2">{email}</Typography>
            <Typography variant="body2">{phone}</Typography>
            {company && (
              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.customer_profile.label.company',
                })}
                : {company}
              </Typography>
            )}
            {identifier && (
              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.customer_profile.label.customer_id',
                })}
                : {identifier}
              </Typography>
            )}
            {address && (
              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.customer_profile.label.address',
                })}
                : {address}
              </Typography>
            )}
            {apartment && (
              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.customer_profile.label.room',
                })}
                : {apartment}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Popover>
    </>
  )
}

export default UserAvatar
