import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { type GridColDef, useGridApiRef } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'

import { DataTable, MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyCustomerIcon from 'assets/icons/empty_customer.svg'
import useRoute from 'hooks/useNavigate'
import { type Customer } from 'types'
import { portalSettingState } from 'state/portalSettingStates'
import { CustomerProfileInputType, Path } from '../commonConstants'
import useApi from 'hooks/useApi'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import usePortalSetting from 'hooks/usePortalSetting'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import { type ItemBasic } from 'components/item/itemTypes'
import ErrorIcon from 'assets/icons/error_icon.svg'

const CustomersWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`

const CustomerListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const {
    data: customersData,
    isLoading,
    mutate: mutateCustomers,
  } = useSWR<Customer[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/customers`
      : null,
  )
  const { goTo } = useRoute()
  const dataTableRef = useGridApiRef()
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null,
  )
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { sendPostRequest } = useApi()
  const { getLocalizedContent } = usePortalSetting()
  const { checkAccesses } = useMember()

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    customer: Customer,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedCustomer(customer)
  }

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const results: GridColDef[] = []

    if (
      portalSetting.portalInfo?.customerProfile.name !==
      CustomerProfileInputType.HIDDEN
    ) {
      results.push({
        field: 'firstName',
        headerName: formatMessage({ id: 'member_edit.label.first_name' }),
        flex: 1,
        renderCell: (params) => params.row.firstName,
      })
      results.push({
        field: 'lastName',
        headerName: formatMessage({ id: 'member_edit.label.last_name' }),
        flex: 1,
        renderCell: (params) => params.row.lastName,
      })
    }

    results.push(
      {
        field: 'contacts',
        headerName: formatMessage({ id: 'customer_list.label.contacts' }),
        flex: 3,
        valueGetter: (params) => `${params.row.email}, ${params.row.phone}`,
        renderCell: (params) => (
          <Stack>
            {params.row.email && (
              <Typography variant="body2">{params.row.email}</Typography>
            )}
            {params.row.phone && (
              <Typography variant="body2">{params.row.phone}</Typography>
            )}
          </Stack>
        ),
      },
      {
        field: 'items',
        headerName: formatMessage({ id: 'customer_list.label.items' }),
        flex: 3,
        valueGetter: (params) =>
          params.row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
        renderCell: (params) =>
          params.row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
      },
    )

    if (checkAccesses({ [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE] })) {
      results.push({
        field: 'action',
        type: 'actions',
        headerName: formatMessage({ id: 'member_list.label.actions' }),
        getActions: ({ row }) => {
          return [
            <IconButton
              key={row.id}
              onClick={(event) => {
                handleOpenMenu(event, row)
              }}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.see_more',
              })}
            >
              <MoreVertIcon
                sx={{
                  fontSize: 16,
                }}
              />
            </IconButton>,
          ]
        },
      })
    }

    return results
  }, [portalSetting])

  const handleRowClick = useCallback(
    (row: Customer): void => {
      if (
        checkAccesses({ [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE] }) &&
        portalSetting
      ) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/customers/${row.id}`,
          row,
          false,
        )

        goTo(`${Path.CUSTOMERS_EDIT}/${row.id}`)
      }
    },
    [portalSetting],
  )

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleDeactivateCustomer = (): void => {
    handleCloseMenu()
    setIsConfirmDialogOpen(true)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleActivateCustomer = (): void => {
    handleCloseMenu()
    void handleConfirmedUpdateActive(true)
  }

  const handleConfirmedUpdateActive = useCallback(
    async (active: boolean): Promise<void> => {
      if (portalSetting && selectedCustomer && customersData?.length) {
        try {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/customers/${selectedCustomer.id}:${
              active ? 'activate' : 'deactivate'
            }`,
          )

          const copyData = [...customersData]
          const targetIndex = copyData.findIndex(
            (data) => data.id === selectedCustomer.id,
          )
          copyData[targetIndex].active = active

          await mutateCustomers(copyData)

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
        } catch (error) {
          console.error(error)
        } finally {
          handleCloseConfirmDialog()
        }
      }
    },
    [portalSetting, selectedCustomer, customersData],
  )

  const handleEditCustomer = (): void => {
    if (selectedCustomer) {
      handleRowClick(selectedCustomer)
    }
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'customer_list.header' })}
          </MainHeader>
        </Box>

        {customersData &&
          customersData?.length > 0 &&
          checkAccesses({
            [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.CUSTOMERS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'customer_list.button.create_customer',
              })}
            </Button>
          )}
      </Stack>

      {customersData?.length === 0 &&
        checkAccesses({ [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE] }) && (
          <CustomersWrapper
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyCustomerIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'customer_list.label.start_creating_item',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    goTo(Path.CUSTOMERS_ADD)
                  }}
                  color="secondary"
                >
                  {formatMessage({
                    id: 'customer_list.button.create_customer',
                  })}
                </Button>
              </Box>
            </Stack>
          </CustomersWrapper>
        )}

      <Box flexGrow={1} overflow="hidden">
        {isLoading && <CircularProgress />}

        {!!customersData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={customersData ?? []}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'firstName', sort: 'asc' }],
              },
            }}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            getRowHeight={() => 'auto'}
            getRowClassName={(params) =>
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${params.row.active ? 'active' : 'inactive'}`
            }
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Box>

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={handleEditCustomer}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'general.icon_button.edit',
            })}
          </ListItemText>
        </MenuItem>

        {selectedCustomer?.active && (
          <MenuItem onClick={handleDeactivateCustomer}>
            <ListItemIcon>
              <BlockIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'member_list.menu.deactivate',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {!selectedCustomer?.active && (
          <MenuItem onClick={handleActivateCustomer}>
            <ListItemIcon>
              <CheckIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'member_list.menu.activate',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={isConfirmDialogOpen}>
        <DialogTitle>
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'customer_list.deactivate_confirm.title' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'customer_list.deactivate_confirm.content' })}
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <Button
              onClick={() => {
                void handleConfirmedUpdateActive(false)
              }}
              variant="contained"
              fullWidth
              color="error"
            >
              {formatMessage({
                id: 'member_list.menu.deactivate',
              })}
            </Button>
            <Button
              onClick={handleCloseConfirmDialog}
              variant="outlined"
              fullWidth
              color="error"
              autoFocus
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default CustomerListPage
