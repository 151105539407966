import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CommentInput from 'components/form/CommentInput'
import {
  COMMENT_ATTACHMENTS_MAX_COUNT,
  COMMENT_MAX_LENGTH,
} from 'commonConstants'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import { SmallInfoText } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import ResourceList from 'components/resource/ResourceList'
import UserAvatar from 'components/user/UserAvatar'
import { type CaseTask, type TaskNote } from 'components/task/taskTypes'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { type ProcessedFile } from 'utils/fileTypes'
import Divider from '@mui/material/Divider'
import { shortenUuid } from 'utils/stringUtils'

type TaskNoteListProps = {
  task: CaseTask
  onSend?: (newNote: TaskNote) => Promise<void>
}

const Wrapper = styled(Stack)`
  width: 100%;
`

const SenderName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

const NotesWrapper = styled(Stack)`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  white-space: pre-line;

  .REPORTER {
    margin-right: 50px;
    background: ${({ theme }) => theme.palette.info.light};
  }

  .MEMBER {
    margin-left: 50px;
    background: ${({ theme }) => theme.palette.info.dark};
  }
`

const NoteWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`

const FORM_NAME = 'taskNotes'

const TaskNoteList: React.FC<TaskNoteListProps> = ({ task, onSend }) => {
  const { caseId } = useParams()
  const { formatMessage } = useIntl()
  const { formatDate } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isSending, setIsSending] = useState(false)
  const { sendPostRequest } = useApi()

  const handleSend = useCallback(
    async (comment: string, processedFiles: ProcessedFile[]): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSending(true)

          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}/tasks/${task.id}/notes`,
            {
              content: comment,
              resources: processedFiles.map((processedFormFile) => ({
                uri: processedFormFile.url,
                name: processedFormFile.file.name,
                format: processedFormFile.format,
              })),
            },
          )

          const responseData = await response.json()

          if (onSend) {
            await onSend(responseData)
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsSending(false)
        }
      }
    },
    [portalSetting, task],
  )

  const sortedNotes = useMemo(
    () =>
      task.notes.sort((a, b) => {
        const aUpdated = new Date(a.updated)
        const bUpdated = new Date(b.updated)
        return bUpdated.getTime() - aUpdated.getTime()
      }),
    [task.notes],
  )

  return (
    <Wrapper data-testid="note-list">
      <Stack spacing={2}>
        {task._operations.canAddNote && (
          <CommentInput
            formName={FORM_NAME}
            maxLength={COMMENT_MAX_LENGTH}
            accept={[
              FILE_INPUT_ACCEPT_TYPE.IMAGE,
              FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
              FILE_INPUT_ACCEPT_TYPE.EMAIL,
            ]}
            limit={COMMENT_ATTACHMENTS_MAX_COUNT}
            placeholder={formatMessage({ id: 'task_list.label.add_note' })}
            onSend={handleSend}
            isSending={isSending}
          />
        )}

        {!!sortedNotes?.length && (
          <NotesWrapper spacing={1}>
            {sortedNotes?.map((note) => (
              <NoteWrapper key={note.id} paddingX={2} paddingY={1.5}>
                <Stack direction="row" spacing={1}>
                  <Stack
                    flexGrow={1}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <UserAvatar
                      avatarUrl={note.author.avatarUrl ?? ''}
                      size={30}
                      name={note.author.displayName}
                    />
                    <SenderName>{note.author.displayName}</SenderName>
                  </Stack>

                  <Stack>
                    <Stack direction="row" spacing={1}>
                      <SmallInfoText>{formatDate(note.updated)}</SmallInfoText>
                      <Divider orientation="vertical" flexItem />
                      <SmallInfoText>ID {shortenUuid(note.id)}</SmallInfoText>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack marginTop={1} spacing={1}>
                  <ResourceList resources={note.resources} size={117} max={2} />
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                    {note.content}
                  </Typography>
                </Stack>
              </NoteWrapper>
            ))}
          </NotesWrapper>
        )}
      </Stack>
    </Wrapper>
  )
}

export default TaskNoteList
