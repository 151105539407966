export enum CaseLogReferenceType {
  CASE = 'CASE',
  TASK = 'TASK',
  COMMENT = 'COMMENT',
  FILE = 'FILE',
}

export enum Platform {
  WEB = 'WEB',
  PUBLIC_WEB = 'PUBLIC_WEB',
  MOBILE = 'MOBILE',
  SYSTEM = 'SYSTEM',
}

export enum CaseLogOperation {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIDEO_CALL = 'VIDEO_CALL',
  FILE_UPLOAD = 'FILE_UPLOAD',
  FORWARD = 'FORWARD',
  EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
}

export const CASE_FIELDS_LABELS: Record<string, string> = {
  shareability: 'case_list.cases.header.shareability',
  location_name: 'address_input.placeholder.address',
  location_latitude: 'logs.case.latitude',
  location_longitude: 'logs.case.longitude',
  location_postalCode: 'logs.case.postal_code',
  location_country: 'logs.case.country',
  occurred: 'case_add.label.occurred',
  description: 'case_add.step.case_information.label.description',
  title: 'case_add.step.case_information.label.title',
  anonymous: 'case_add.label.send_anonymously',
  status: 'case_list.cases.header.status',
  resources: 'case_detail.tab.label.files',
  category: 'logs.case.category',
  forwarding_subject: 'contact_edit.email_template.subject',
  forwarding_body: 'contact_edit.email_template.body',
  forwarding_to: 'contact_edit.email_template.to',
  forwarding_cc: 'contact_edit.email_template.cc',
  forwarding_reporterEmail: 'case_add.step.reporter_information.label.email',
  error: 'logs.case.error',
  logEventName: 'logs.comment.event_name',
  email: 'logs.comment.email',
}

export const TASK_FIELDS_LABELS: Record<string, string> = {
  description: 'action_edit_form.label.description',
  deadline: 'action_list.button.set_deadline',
  assigneeFullName: 'action_edit_form.label.assign_to',
  assigneePartyName: 'task_edit_form.label.teams',
  status: 'logs.task.status',
  taskAssignedEventType: 'action_edit_form.label.assign_to',
  logEventName: 'logs.comment.event_name',
  content: 'logs.task.content',
  filename: 'logs.task.filename',
}

export const FILE_FIELDS_LABELS: Record<string, string> = {
  description: 'file_edit_form.label.description',
  name: 'logs.file.file_name',
  source: 'logs.file.upload_source',
}

export const COMMENT_FIELDS_LABELS: Record<string, string> = {
  comment: 'logs.comment.comment',
  logEventName: 'logs.comment.event_name',
  email: 'logs.comment.email',
}
