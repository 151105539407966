import { type ReactNode } from 'react'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MainMenu, {
  MENU_FOLDED_WIDTH,
  MENU_FULL_WIDTH,
} from 'components/MainMenu'
import { ContentWrapper } from 'components/StyledComponents'
import { isMenuOpenState } from 'state/layoutStates'
import { useRecoilValue } from 'recoil'

type WithMenuLayoutProps = {
  children: ReactNode
}

export const RightSideBox = styled('main')<{ open: boolean }>`
  width: ${({ open, theme }) =>
    open
      ? `calc(100% - ${MENU_FULL_WIDTH}px - ${theme.spacing(2)})`
      : `calc(100% - ${MENU_FOLDED_WIDTH}px - ${theme.spacing(2)})`};
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  flex-grow: 1;
  min-width: 700px;
`

export const Nav = styled('nav')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`

const WithMenuLayout: React.FC<WithMenuLayoutProps> = ({ children }) => {
  const isOpen = useRecoilValue(isMenuOpenState)

  return (
    <Stack direction="row" spacing={2} height={'100%'} minHeight={680}>
      <Nav>
        <MainMenu />
      </Nav>
      <RightSideBox open={isOpen}>
        <ContentWrapper height={'100%'} padding={2}>
          {children}
        </ContentWrapper>
      </RightSideBox>
    </Stack>
  )
}

export default WithMenuLayout
