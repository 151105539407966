import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { type GridColDef, useGridApiRef } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'

import { DataTable, MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyMemberIcon from 'assets/icons/empty_member.svg'
import useRoute from 'hooks/useNavigate'
import { type Member, type TeamBasic } from 'types'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { Path } from '../commonConstants'
import { type RoleBasic } from 'components/role/roleTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import ErrorIcon from 'assets/icons/error_icon.svg'

const MembersWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`

const MemberListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const {
    data: membersData,
    isLoading,
    mutate: mutateMembers,
  } = useSWR<Member[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/members`
      : null,
  )
  const { goTo } = useRoute()
  const dataTableRef = useGridApiRef()
  const [selectedMember, setSelectedMember] = useState<Member | null>(null)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { sendPostRequest } = useApi()
  const { checkAccesses } = useMember()

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    member: Member,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedMember(member)
  }

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const columnsData: GridColDef[] = [
      {
        field: 'firstName',
        headerName: formatMessage({ id: 'member_edit.label.first_name' }),
        flex: 1,
        renderCell: (params) => params.row.firstName,
      },
      {
        field: 'lastName',
        headerName: formatMessage({ id: 'member_edit.label.last_name' }),
        flex: 1,
        renderCell: (params) => params.row.lastName,
      },
      {
        field: 'jobTitle',
        headerName: formatMessage({ id: 'member_edit.label.job_title' }),
        flex: 2,
        renderCell: (params) => params.row.jobTitle,
      },
      {
        field: 'email',
        headerName: formatMessage({ id: 'member_list.label.email' }),
        flex: 3,
        renderCell: (params) => params.row.email,
      },
      {
        field: 'role',
        headerName: formatMessage({ id: 'member_list.label.role' }),
        flex: 1,
        valueGetter: (params) =>
          params.row.roles.map((role: RoleBasic) => role.name).join(', '),
        renderCell: (params) =>
          params.row.roles.map((role: RoleBasic) => role.name).join(', '),
      },
      {
        field: 'team',
        headerName: formatMessage({ id: 'member_list.label.team' }),
        flex: 1,
        valueGetter: (params) =>
          params.row.teams.map((team: TeamBasic) => team.name).join(', '),
        renderCell: (params) =>
          params.row.teams.map((team: TeamBasic) => team.name).join(', '),
      },
    ]

    if (checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] })) {
      columnsData.push({
        field: 'action',
        type: 'actions',
        getActions: ({ row }) => [
          <IconButton
            key={row.id}
            onClick={(event) => {
              handleOpenMenu(event, row)
            }}
            size="small"
            aria-label={formatMessage({
              id: 'general.icon_button.see_more',
            })}
            role="button"
            color="inherit"
          >
            <MoreVertIcon
              sx={{
                fontSize: 16,
              }}
            />
          </IconButton>,
        ],
      })
    }

    return columnsData
  }, [portalSetting])

  const handleRowClick = useCallback(
    (row: Member): void => {
      if (
        portalSetting &&
        checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] })
      ) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members/${row.id}`,
          row,
          false,
        )

        goTo(`${Path.MEMBERS_EDIT}/${row.id}`)
      }
    },
    [portalSetting],
  )

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleDeactivateMember = (): void => {
    handleCloseMenu()
    setIsConfirmDialogOpen(true)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleActivateMember = (): void => {
    handleCloseMenu()
    void handleConfirmedUpdateActive(true)
  }

  const handleConfirmedUpdateActive = useCallback(
    async (active: boolean): Promise<void> => {
      if (portalSetting && selectedMember && membersData?.length) {
        try {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/members/${selectedMember.id}:${
              active ? 'activate' : 'deactivate'
            }`,
          )

          const newMembersData = membersData.map((member) => {
            if (member.id === selectedMember.id) {
              return {
                ...member,
                active,
              }
            }

            return member
          })

          await mutateMembers(newMembersData)

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
        } catch (error) {
          console.error(error)
        } finally {
          handleCloseConfirmDialog()
        }
      }
    },
    [portalSetting, selectedMember, membersData],
  )

  const handleEditMember = (): void => {
    if (selectedMember) {
      handleRowClick(selectedMember)
    }
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>{formatMessage({ id: 'member_list.header' })}</MainHeader>
        </Box>

        {membersData &&
          membersData?.length > 0 &&
          checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.MEMBERS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'member_list.button.create_member',
              })}
            </Button>
          )}
      </Stack>

      {membersData?.length === 0 &&
        checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] }) && (
          <MembersWrapper
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyMemberIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'member_list.label.start_creating_member',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    goTo(Path.MEMBERS_ADD)
                  }}
                  color="secondary"
                >
                  {formatMessage({
                    id: 'member_list.button.create_member',
                  })}
                </Button>
              </Box>
            </Stack>
          </MembersWrapper>
        )}

      <Box flexGrow={1} overflow="hidden">
        {isLoading && <CircularProgress />}

        {!!membersData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={membersData ?? []}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'firstName', sort: 'asc' }],
              },
            }}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            getRowHeight={() => 'auto'}
            getRowClassName={(params) =>
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${params.row.active ? 'active' : 'inactive'}`
            }
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Box>

      <Menu
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        role="menu"
      >
        <MenuItem onClick={handleEditMember}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'general.icon_button.edit',
            })}
          </ListItemText>
        </MenuItem>

        {selectedMember?.active && (
          <MenuItem onClick={handleDeactivateMember}>
            <ListItemIcon>
              <BlockIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'member_list.menu.deactivate',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {!selectedMember?.active && (
          <MenuItem onClick={handleActivateMember}>
            <ListItemIcon>
              <CheckIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'member_list.menu.activate',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={isConfirmDialogOpen}>
        <DialogTitle>
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'member_list.deactivate_confirm.title' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'member_list.deactivate_confirm.content' })}
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <Button
              onClick={() => {
                void handleConfirmedUpdateActive(false)
              }}
              variant="contained"
              fullWidth
              color="error"
            >
              {formatMessage({
                id: 'member_list.menu.deactivate',
              })}
            </Button>
            <Button
              onClick={handleCloseConfirmDialog}
              variant="outlined"
              fullWidth
              color="error"
              autoFocus
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default MemberListPage
