import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

import { type Role } from 'components/role/roleTypes'
import { PortalType } from '../../commonConstants'
import {
  SubContentWrapper,
  SubHeader,
  SubSubHeader,
} from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import {
  CaseAccess,
  FeatureAccess,
  PortalSection,
} from 'components/role/roleConstants'
import useMember from 'hooks/useMember'

type MemberRoleDetailProps = {
  role: Role
  onEdit: () => void
  onDelete: () => void
  onCopy: () => void
}

const RowWrapper = styled(Stack)`
  width: 100%;
  padding: 20px;

  .disabled {
    color: #bbc6f1;
  }
`

const Subtitle = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
`

const CheckIcon = styled(CheckCircleIcon)`
  color: #7adcd0;
  font-size: 19px;
`

const UncheckIcon = styled(CancelIcon)`
  color: #e9ecfc;
  font-size: 19px;
`

const SmallCheckIcon = styled(CheckIcon)`
  font-size: 16px;
`

const SmallUncheckIcon = styled(UncheckIcon)`
  font-size: 16px;
`

const AccessWrapper = styled(Stack)`
  flex-direction: row;
  gap: 16px;
  color: #bbc6f1;
  font-size: 14px;
  align-items: center;
`

const AccessLabel = styled(Box)`
  padding: ${({ theme }) => theme.spacing(0.5, 1)};

  &.selected {
    color: white;
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    font-weight: 500;
  }
`

const MemberRoleDetail: React.FC<MemberRoleDetailProps> = ({
  role,
  onEdit,
  onDelete,
  onCopy,
}) => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)

  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const { checkAccesses } = useMember()

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuEl(event.currentTarget)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleDelete = (): void => {
    onDelete()
    handleCloseMenu()
  }

  const renderFeatureAccess = (
    featureAccess: FeatureAccess,
  ): React.ReactNode => {
    return (
      <AccessWrapper>
        <AccessLabel
          className={featureAccess === FeatureAccess.NONE ? 'selected' : ''}
        >
          {formatMessage({
            id: 'member_role_detail.access.hidden',
          })}
        </AccessLabel>
        <AccessLabel
          className={featureAccess === FeatureAccess.READ ? 'selected' : ''}
        >
          {formatMessage({
            id: 'member_role_detail.access.view',
          })}
        </AccessLabel>
        <AccessLabel
          className={featureAccess === FeatureAccess.WRITE ? 'selected' : ''}
        >
          {formatMessage({
            id: 'member_role_detail.access.edit',
          })}
        </AccessLabel>
      </AccessWrapper>
    )
  }

  return (
    <Stack width={'100%'} spacing={2}>
      <RowWrapper direction={'row'}>
        <Box flexGrow={1}>
          <SubHeader>{role.name}</SubHeader>
        </Box>

        {checkAccesses({ [PortalSection.ROLES]: [FeatureAccess.WRITE] }) && (
          <IconButton
            onClick={(event): void => {
              handleOpenMenu(event)
            }}
            aria-label={formatMessage({
              id: 'general.icon_button.see_more',
            })}
            color="inherit"
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </RowWrapper>

      <RowWrapper
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        marginLeft={2}
      >
        {role.default ? <CheckIcon /> : <UncheckIcon />}
        <Subtitle className={role.default ? '' : 'disabled'}>
          {formatMessage({
            id: 'member_role_list.label.default_role',
          })}
        </Subtitle>
      </RowWrapper>
      <Divider />

      <RowWrapper>
        <SubSubHeader>
          {formatMessage({
            id: 'member_role_detail.label.case_visibility',
          })}
        </SubSubHeader>

        <SubContentWrapper direction="row" alignItems="center" spacing={1}>
          <CheckIcon />

          {role.caseAccess === CaseAccess.ALL && (
            <Stack>
              <Subtitle>
                {formatMessage({
                  id: 'member_role_detail.case_visibility.all_cases.title',
                })}
              </Subtitle>
              <Typography variant="body2">
                {formatMessage({
                  id: 'member_role_detail.case_visibility.all_cases.description',
                })}
              </Typography>
            </Stack>
          )}
          {role.caseAccess === CaseAccess.ITEM_BASED && (
            <Stack>
              <Subtitle>
                {formatMessage({
                  id: 'member_role_detail.case_visibility.assigned_items.title',
                })}
              </Subtitle>
              <Typography variant="body2">
                {formatMessage({
                  id: 'member_role_detail.case_visibility.assigned_items.description',
                })}
              </Typography>
            </Stack>
          )}
          {role.caseAccess === CaseAccess.TASK_BASED && (
            <Stack>
              <Subtitle>
                {formatMessage({
                  id: 'member_role_detail.case_visibility.assigned_tasks.title',
                })}
              </Subtitle>
              <Typography variant="body2">
                {formatMessage({
                  id: 'member_role_detail.case_visibility.assigned_tasks.description',
                })}
              </Typography>
            </Stack>
          )}
          {role.caseAccess === CaseAccess.NONE && (
            <Stack>
              <Subtitle>
                {formatMessage({
                  id: 'member_role_detail.case_visibility.none.title',
                })}
              </Subtitle>
              <Typography variant="body2">
                {formatMessage({
                  id: 'member_role_detail.case_visibility.none.description',
                })}
              </Typography>
            </Stack>
          )}
        </SubContentWrapper>
      </RowWrapper>

      <Divider />

      <RowWrapper>
        <SubSubHeader>
          {formatMessage({
            id: 'member_role_detail.label.general_access',
          })}
        </SubSubHeader>

        <Stack
          direction={'row'}
          spacing={1}
          width={'100%'}
          marginLeft={2}
          paddingTop={2}
          paddingBottom={1}
        >
          <Stack flexGrow={1} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {role.platformAccess.web ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={role.platformAccess.web ? '' : 'disabled'}
              >
                {formatMessage({
                  id: 'member_role_detail.general_access.can_access_portal',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {role.platformAccess.mobile ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={role.platformAccess.mobile ? '' : 'disabled'}
              >
                {formatMessage({
                  id: 'member_role_detail.general_access.can_access_service_app',
                })}
              </Typography>
            </Stack>
          </Stack>

          <Stack flexGrow={1} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {role.assignmentEligibility.cases ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={role.assignmentEligibility.cases ? '' : 'disabled'}
              >
                {formatMessage({
                  id: 'member_role_detail.general_access.can_be_case_handler',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {role.assignmentEligibility.tasks ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={role.assignmentEligibility.tasks ? '' : 'disabled'}
              >
                {formatMessage({
                  id: 'member_role_detail.general_access.can_be_assigned_to_tasks',
                })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </RowWrapper>

      {role.platformAccess.web && (
        <>
          <Divider />

          <RowWrapper>
            <SubSubHeader>
              {formatMessage({
                id: 'member_role_detail.label.administration',
              })}
            </SubSubHeader>

            <Stack width={'100%'} spacing={1}>
              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.members',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.members)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.teams',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.teams)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.customers',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.customers)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.contacts',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.contacts)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.comment_templates',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.commentTemplates)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.polls',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.polls)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.news',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.news)}
              </SubContentWrapper>

              {portalSetting?.type === PortalType.CITY && (
                <SubContentWrapper direction={'row'} alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'member_role_detail.administration.alerts',
                      })}
                    </Typography>
                  </Box>

                  {renderFeatureAccess(role.adminSectionAccess.alerts)}
                </SubContentWrapper>
              )}

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.items',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.items)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.categories',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.categories)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.roles',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.roles)}
              </SubContentWrapper>

              <SubContentWrapper direction={'row'} alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'member_role_detail.administration.settings',
                    })}
                  </Typography>
                </Box>

                {renderFeatureAccess(role.adminSectionAccess.settings)}
              </SubContentWrapper>
            </Stack>
          </RowWrapper>
        </>
      )}

      {/* <Divider />

      <RowWrapper>
        <Subtitle>
          {formatMessage({
            id: 'member_role_detail.label.case_handling',
          })}
        </Subtitle>

        <Stack
          direction={'row'}
          spacing={1}
          width={'100%'}
          marginLeft={2}
          paddingTop={2}
          paddingBottom={1}
        >
          <Stack flexGrow={1} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_CREATE_CASE,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(CaseHandling.CAN_CREATE_CASE)
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_create_cases',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_DELETE_CASE,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(CaseHandling.CAN_DELETE_CASE)
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_delete_cases',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_EDIT_CASE_TITLE,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_EDIT_CASE_TITLE,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_edit_case_title',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_EDIT_CASE_CATEGORIES,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_EDIT_CASE_CATEGORIES,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_edit_case_categories',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_UPDATE_CASE_STATUS,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_UPDATE_CASE_STATUS,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_edit_case_status',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_MAKE_VIDEO_CALL,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_MAKE_VIDEO_CALL,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_make_video_call',
                })}
              </Typography>
            </Stack>
          </Stack>

          <Stack flexGrow={1} spacing={1}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_SEND_COMMENT,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_SEND_COMMENT,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_send_comment',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_SET_TASK_DEADLINE,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_SET_TASK_DEADLINE,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_set_task_deadline',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_UPDATE_TASK_STATUS,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(
                    CaseHandling.CAN_UPDATE_TASK_STATUS,
                  )
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_edit_task_status',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(CaseHandling.CAN_ADD_FILE) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(CaseHandling.CAN_ADD_FILE)
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_add_file',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(CaseHandling.CAN_EDIT_FILE) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(CaseHandling.CAN_EDIT_FILE)
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_edit_file',
                })}
              </Typography>
            </Stack>

            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              {memberRole.caseAccesses.includes(
                CaseHandling.CAN_DELETE_FILE,
              ) ? (
                <SmallCheckIcon />
              ) : (
                <SmallUncheckIcon />
              )}
              <Typography
                variant="body2"
                className={
                  memberRole.caseAccesses.includes(CaseHandling.CAN_DELETE_FILE)
                    ? ''
                    : 'disabled'
                }
              >
                {formatMessage({
                  id: 'member_role_detail.case_access.can_delete_file',
                })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </RowWrapper> */}

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'member_role_detail.menu.edit',
            })}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={onCopy}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            {formatMessage({
              id: 'member_role_detail.menu.copy',
            })}
          </ListItemText>
        </MenuItem>

        {!role.default && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'member_role_detail.menu.delete',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Stack>
  )
}

export default MemberRoleDetail
