import { RecoilRoot, type SetRecoilState } from 'recoil'

import AppProviders from './AppProviders'
import { AuthProvider } from './context/AuthProvider'
import { currentLocaleState } from 'state/portalSettingStates'
import ErrorBoundary from 'components/ErrorBoundary'

import { ThemeMode } from 'commonConstants'
import { themeModeState } from 'state/layoutStates'
import { columnVisibilityState, sortModelState } from 'state/caseListStates'

function initializeState({ set }: { set: SetRecoilState }): void {
  try {
    const userLanguage = localStorage.getItem('userLanguage')
    if (userLanguage) {
      set(currentLocaleState, userLanguage)
    }

    const systemTheme = window.matchMedia?.('(prefers-color-scheme: dark)')
      .matches
      ? ThemeMode.DARK
      : ThemeMode.LIGHT

    const storedTheme = localStorage.getItem('themeMode')
    const themeMode =
      storedTheme === ThemeMode.DARK || storedTheme === ThemeMode.LIGHT
        ? storedTheme
        : systemTheme

    set(themeModeState, themeMode)

    const casesColumnVisibilityString = localStorage.getItem(
      'casesColumnVisibility',
    )
    if (casesColumnVisibilityString) {
      const casesColumnVisibility = JSON.parse(casesColumnVisibilityString)
      if (Object.keys(casesColumnVisibility).length > 0) {
        set(columnVisibilityState, casesColumnVisibility)
      }
    }

    const casesSortModelString = localStorage.getItem('casesSortModel')
    if (casesSortModelString) {
      const casesSortModel = JSON.parse(casesSortModelString)
      if (casesSortModel.length > 0) {
        set(sortModelState, casesSortModel)
      }
    }
  } catch (error) {
    console.error('Error initializing Recoil state from localStorage:', error)
  }
}

const App: React.FC = () => (
  <ErrorBoundary>
    <RecoilRoot initializeState={initializeState}>
      <AuthProvider>
        <AppProviders />
      </AuthProvider>
    </RecoilRoot>
  </ErrorBoundary>
)

export default App
