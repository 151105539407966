import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

import { hasNewFileState, processedFilesState } from 'state/formStates'
import { extractFilenameFromUrl } from 'utils/fileUtils'
import { FileType, PromiseStatus } from '../../commonConstants'
import FileUploader from 'components/form/FileUploader'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'

type ImageUploaderProps = {
  formName: string
  maxImageWidth: number
  maxImageHeight: number
  defaultImageUrl?: string
  hintText?: string
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  formName,
  maxImageWidth,
  maxImageHeight,
  defaultImageUrl,
  hintText,
}) => {
  const setProcessedFiles = useSetRecoilState(processedFilesState)
  const setHasNewFile = useSetRecoilState(hasNewFileState)

  useEffect(() => {
    if (defaultImageUrl) {
      const filename = extractFilenameFromUrl(defaultImageUrl)
      setProcessedFiles([
        {
          id: filename,
          formName,
          status: PromiseStatus.FULFILLED,
          file: new File([], filename, { type: 'image/png' }),
          url: defaultImageUrl,
          format: FileType.IMAGE,
        },
      ])
      setHasNewFile(false)
    }
  }, [defaultImageUrl])

  return (
    <FileUploader
      formName={formName}
      limit={1}
      accept={[FILE_INPUT_ACCEPT_TYPE.IMAGE]}
      toJpg={false}
      maxImageWidth={maxImageWidth}
      maxImageHeight={maxImageHeight}
      addFileIcon={AddPhotoAlternateIcon}
      hintText={hintText}
    />
  )
}

export default ImageUploader
